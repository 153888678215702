import React from 'react'
import useTimeOffDispatchers from '../../../redux/dispatchers/useTimeOffDispatchers'
import { useTimeOffCreateErrorMessageSelector, useTimeOffCreateStatusSelector, useTimeOffUpdateErrorMessageSelector, useTimeOffUpdateStatusSelector } from '../../../redux/selectors/useTimeOffSelectors'
import * as asyncStates from "../../../redux/constants/asyncStates";
import { useEffect } from 'react';
import { Box, ErrorAndSuccess, FlexBox } from '../../../Components/Boxes';
import { Text } from '../../../Components/Text';
import { Button, DropDownButton, TextBox, Textarea } from '../../../Components/Input';
import { useState } from 'react';
import { formateDateTextboxFormatToDate, formateDateToDateTextboxFormat } from '../../../utiles/dateformate';
import { SearchUserSelectBox } from '../../components/searchSelectBox';
import CircularLoader from '../../../Components/Icons/circularLoader';
import { TIME_OFF_STATUS } from '../../../constants';
import { ConfirmationDialog, DropDownMenuItem } from '../../../Components/Modals';
import Icon from '../../../Components/Icons';
import { useMemo } from 'react';

export default function AddTimeOffDialog(props) {

    const { $handleDialog, $staffId, $timeOffToEdit } = props

    const [staffId, setStaffId] = useState($staffId || $timeOffToEdit?.staff?.id || null)

    const [staff, setStaff] = useState($timeOffToEdit?.staff ?? null)
    // approversRequired
    const [name, setName] = useState($timeOffToEdit?.name ?? "")
    const [reason, setReason] = useState($timeOffToEdit?.reason ?? "")
    const [notes, setNotes] = useState($timeOffToEdit?.notes ?? "")
    const [startDate, setStartDate] = useState($timeOffToEdit?.startDate ?? new Date())
    const [endDate, setEndDate] = useState($timeOffToEdit?.endDate ?? new Date())
    const [timeOffStatus, setTimeOffStatus] = useState($timeOffToEdit?.timeOffStatus)
    const [confirmationDialog, setConfirmationDialog] = useState(null)

    const canApprove = useMemo(() => {
        if (!$timeOffToEdit) return false
        return $timeOffToEdit?.approversRequired?.includes(localStorage.getItem("staffId")) && $timeOffToEdit.timeOffStatus == TIME_OFF_STATUS.PENDING
    }, [])

    const canCancel = useMemo(() => {
        if (!$timeOffToEdit) return false
        if ($timeOffToEdit.timeOffStatus == TIME_OFF_STATUS.CANCELLED) return false
        if ($timeOffToEdit.timeOffStatus == TIME_OFF_STATUS.REJECTED) return false
        return true
    }, [])

    const {
        getTimeOffs,
        createTimeOff,
        updateTimeOff,
        clearAllErrorMessages,
        clearAllSuccessMessages,
    } = useTimeOffDispatchers()

    const onCreateTimeOffClicked = async () => {
        await createTimeOff({
            staffId: staffId ?? staff?.id,
            name,
            reason,
            notes,
            startDate,
            endDate,
        }).unwrap()
        $handleDialog()
        getTimeOffs({
            staffId: $staffId,
        })
    }

    const onUpdateTimeOffClicked = async (status) => {
        await updateTimeOff({
            staffId: staffId,
            timeOffId: $timeOffToEdit?.id,
            timeOffStatus: status
        }).unwrap()
        $handleDialog()
        getTimeOffs({
            staffId: $staffId,
        })
    }

    const createStatus = useTimeOffCreateStatusSelector()
    const updateStatus = useTimeOffUpdateStatusSelector()

    const loading = createStatus === asyncStates.PENDING || updateStatus === asyncStates.PENDING

    const createError = useTimeOffCreateErrorMessageSelector()
    const updateError = useTimeOffUpdateErrorMessageSelector()

    const errorMessage = createError || updateError

    useEffect(() => {
        clearAllErrorMessages()
        clearAllSuccessMessages()
    }, [])

    const statusText = (status) => {
        switch (status) {
            case TIME_OFF_STATUS.PENDING:
                return "Pending"
            case TIME_OFF_STATUS.ACCEPTED:
                return "Accepted"
            case TIME_OFF_STATUS.REJECTED:
                return "Rejected"
            case TIME_OFF_STATUS.CANCELLED:
                return "Cancelled"
            default:
                return "Pending"
        }
    }

    const statusColor = (status) => {
        switch (status) {
            case TIME_OFF_STATUS.PENDING:
                return "orange"
            case TIME_OFF_STATUS.ACCEPTED:
                return "green"
            case TIME_OFF_STATUS.REJECTED:
                return "red"
            case TIME_OFF_STATUS.CANCELLED:
                return "red"
            default:
                return "orange"
        }
    }

    return (
        <>
            <Box
                $padding={5}
                $marginRight={3}
            >
                {timeOffStatus && <FlexBox $width="100%" $justifyContent="flex-end">
                    <Box
                        $width="20%"
                        $display="flex"
                        $justifyContent="center"
                        $backgroundColor={statusColor(timeOffStatus)}
                        $marginBottom={3}
                        $borderRadius={10}
                    >
                        <Text
                            $fontWeight="bold"
                            $wordWrap="anywhere"
                            $padding={1}
                            $color={"white"}
                        >
                            {statusText(timeOffStatus)}
                        </Text>
                    </Box>
                </FlexBox>}

                {timeOffStatus == TIME_OFF_STATUS.PENDING && <>
                    <FlexBox $marginTop={3} $marginBottom={3} $width="100%">
                        <ErrorAndSuccess $type="info">
                           {canApprove ? "Currently waiting on your approval" : "Waiting on required approvers"}
                        </ErrorAndSuccess>
                    </FlexBox>
                </>}

                {!$staffId && <>
                    <Text $fontSize="body" $marginBottom={1}>
                        Staff
                    </Text>
                    <SearchUserSelectBox
                        disabled={staffId ? true : false}
                        searchByStaff
                        selected={staff}
                        onChange={(user) => {
                            setStaff(user)
                        }}
                    />
                    <br />
                </>}
                <Text $fontSize="body" $marginBottom={1}>
                    Title
                </Text>
                <TextBox
                    $disabled={!!$timeOffToEdit}
                    $type={"text"}
                    $name={"title"}
                    $value={name}
                    $onChange={(e) => setName(e.target.value)}
                    $width="100%"
                />

                <br />

                <Text $fontSize="body" $marginBottom={1}>
                    Reason
                </Text>
                <Textarea
                    $disabled={!!$timeOffToEdit}
                    $type={"text"}
                    $name={"reason"}
                    $value={reason}
                    $onChange={(e) => setReason(e.target.value)}
                    $width="100%"
                    $rows={5}
                />

                <br />

                <Text $fontSize="body" $marginTop={3} $marginBottom={1}>
                    Notes
                </Text>
                <Textarea
                    $disabled={!!$timeOffToEdit}
                    $type={"text"}
                    $name={"notes"}
                    $value={notes}
                    $onChange={(e) => setNotes(e.target.value)}
                    $width="100%"
                    $rows={5}
                />

                <br />

                <Text $fontSize="body" $marginTop={3} $marginBottom={1}>
                    Start Date
                </Text>
                <TextBox
                    $disabled={!!$timeOffToEdit}
                    $type={"date"}
                    $name={"startDate"}
                    $value={formateDateToDateTextboxFormat(startDate)}
                    $onChange={(e) => {
                        const val = formateDateTextboxFormatToDate(e.target.value);
                        startDate.setMonth(val.getMonth())
                        startDate.setFullYear(val.getFullYear())
                        startDate.setDate(val.getDate())
                        setStartDate(new Date(`${startDate}`))
                    }}
                    $width="100%"
                />

                <br />

                <Text $fontSize="body" $marginBottom={1}>
                    End Date
                </Text>
                <TextBox
                    $disabled={!!$timeOffToEdit}
                    $type={"date"}
                    $name={"endDate"}
                    $value={formateDateToDateTextboxFormat(endDate)}
                    $onChange={(e) => {
                        const val = formateDateTextboxFormatToDate(e.target.value);
                        endDate.setMonth(val.getMonth())
                        endDate.setFullYear(val.getFullYear())
                        endDate.setDate(val.getDate())
                        setEndDate(new Date(`${endDate}`))
                    }}
                    $width="100%"
                />

                {errorMessage && <FlexBox $marginTop={2} $width="100%">
                    <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                </FlexBox>}


            </Box>

            <FlexBox
                $justifyContent={"flex-end"}
                $padding={4}
                $backgroundColor="list"
            >
                {(canCancel || canApprove) && <DropDownButton
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $loading={loading}
                >
                    {canApprove && <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Approval Confirmation",
                                description: "Are you sure you want to approve this time off?",
                                onOk: ({ reason }) => {
                                    onUpdateTimeOffClicked(TIME_OFF_STATUS.ACCEPTED)
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="Done" $size="large" $color="primary" />
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Approve Time Off
                        </Text>
                    </DropDownMenuItem>}

                    {canApprove && <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Rejection Confirmation",
                                description: "Are you sure you want to reject this time off?",
                                onOk: ({ reason }) => {
                                    onUpdateTimeOffClicked(TIME_OFF_STATUS.REJECTED)
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="Clear" $size="large" $color="red" />
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Reject Time Off
                        </Text>
                    </DropDownMenuItem>}

                    {canApprove && canCancel && <hr />}

                    {canCancel && <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Cancellation Confirmation",
                                description: "Are you sure you want to cancel this time off?",
                                onOk: ({ reason }) => {
                                    onUpdateTimeOffClicked(TIME_OFF_STATUS.CANCELLED)
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="Cancel" $size="large" $color="red" />
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Cancel Time Off
                        </Text>
                    </DropDownMenuItem>}

                </DropDownButton>}

                {!$timeOffToEdit && <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() => {
                        if (loading) return;
                        onCreateTimeOffClicked();
                    }}
                >
                    <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                        {createStatus !== asyncStates.PENDING ? (
                            "Save"
                        ) : (
                            <CircularLoader $color="white" $size={25} />
                        )}
                    </Text>
                </Button>}
            </FlexBox>

            {confirmationDialog && <ConfirmationDialog
                $title={confirmationDialog.title}
                $description={confirmationDialog.description}
                $onClose={() => {
                    setConfirmationDialog(null)
                }}
                $onConfirm={({ reason }) => {
                    setConfirmationDialog(null)
                    confirmationDialog.onOk({ reason })
                }}
                $enterReason={confirmationDialog.enterReason}
            />}

        </>
    )
}
